.angucomplete-holder {
  overflow: visible;
  position: relative;
}
.inline-editing {
  .angucomplete-holder {
    display: inline-block !important;
    width: 85% !important;
  }
}

.angucomplete-image-holder {
  width: auto;
  display: inline-block;
}

.angucomplete-dropdown {
  position: absolute;
  box-shadow: 0 0 1px 1px $rgba-black-15, 0 2px 12px 0 $gray-lighter;
  border: 1px solid $gray;
  background: $gray-lightest;
  width: auto;
  z-index: 4;
  overflow-y: auto;
  height: auto;
  max-height: 90vh;
}

.angucomplete-title {
  color: $black !important;
}

.angucomplete-searching {
  padding: .25rem .5rem;
}

.angucomplete-selected-row {
  background: $selected !important;
}
.angucomplete-description {
  color: $gray-darkest;
  font-size: .8rem;
}

.angucomplete-holder.angucomplete-dropdown-visible input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.angucomplete-holder input:focus {
  border-color: $gray;
}

.angucomplete-holder.angucomplete-dropdown-visible .angucomplete-dropdown {
  max-height: 30vh;
  margin-top: -2px;
  box-shadow: none;
  background: $white;
  border-top: 1px solid $gray-lighter;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: auto;
}

.angucomplete-row {
  padding: .25rem .5rem;
  font-size: 1rem;
}

.angucomplete-selected-row {
  background: lighten($person, 45%);
}
