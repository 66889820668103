@import 'sass/defaults/variables';
@import 'sass/defaults/angucomplete';
@import 'sass/components/card';
@import 'sass/modals/modals';

.jb--wrapper {

.icon-left:before        { content: "\25c4"; }
.icon-right:before         { content: "\25ba"; }
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noscript {
  padding: 40px;
  text-align: center;
}

.rgba {
  background: rgba(0,0,0,.1);
}

// General layout jb--wrapper
  font-size: initial;
  display: block;
  width: 100%;
  margin: 0 auto;

  .jb--jobs {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    // color: #000;
    // background: #fff;
    padding: 10px;
  }
  #jb-apply.jb--jobs {
    width: 600px;
  }
  .jb--content-wrapper {
    vertical-align: top;
    width: 100%;
    clear: both;
  }
  .jb--content {
    flex: 1;
    width: 71%;
    vertical-align: top;
    display: inline-block;
  }
  .jb--sidebar {
    width: 25%;
    margin-right: 20px;
    vertical-align: top;
    display: inline-block;
    clear: both;
    .jb--button {
      margin-bottom: 24px;
    }
  }
  .jb--featured-label {
    display: inline-block;
    font-size: 12px;
    margin-left: 4px;
    background: #555555;
    padding: 0 4px;
    border-radius: 2px;
    color: #ffffff;
    font-weight: normal;
    float: right;
  }

  .jb--featured-label-detail {
    display: inline-block;
    margin-bottom: 40px;
    background: #555555;
    padding: 4px 10px;
    border-radius: 2px;
    color: #ffffff;
    font-weight: normal;
  }

  .jb--detail-header {
    margin: 0;
    font-size: 160%;
    line-height: 160%;
    font-weight: bold;
  }
  //old header: e2e-position-title-header//

  // reused styles
  h2 {
    margin: 0;
    margin-bottom: 24px;
    font-size: 160%;
    line-height: 160%;
  }
  .jb--back {
    margin-bottom: 24px;
    display: block;
  }
  .jb--applying-for {
    display: block;
    margin-bottom: 10px;
  }

  // sidebar
  .jb--sidebar-content {
    // border-bottom: 0;
    margin: 0;
    padding: 10px 0;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }
  .jb--sidebar-content {
    &.jb--location {
      display: block;
    }
  }
  .jb--sidebar-title {
    text-transform: uppercase;
    display: block;
    clear: both;
    font-weight: 700;
  }

  /* Form/Label Defaults */

  .jb--form-row {
    margin-bottom: 24px;
  }
  .jb--form-row-privacy {
    margin-bottom: 24px;
    display: flex;
    font-size: 13px;
  }
  .privacyText {
    margin: 1px 0 0 3px;
    font-weight: bold;
  }
  .jb--data-field {
    display: inline-block;
  }
  .jb--label {
    display: block;
    clear: both;
    margin-bottom: 4px;
    font-weight: 700;
  }
  .jb--required {
    color: $danger;
  }
  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    padding: 8px;
    width: 260px;
    border: 1px solid #ccc;
    font-size: initial;
    margin-bottom: 0;
  }
  select {
    margin-right: 0px !important;
  }
  button[type="file"] {
    //height: 26px;
    padding: .25rem .8rem;
    font-size: initial;
    background: #fff;
  }
  input[type="file"] {
    width: auto;
    margin-right: 4px;
  }
  input, button, .jb--button {
      //height: 36px;
  }

  /* consent-to-text label on apply page */
  .jb--consent-to-text-row {
    display: flex;
    align-items: start;
  }
  .jb--consent-to-text-row.disabled {
    color: $gray-dark;
  }
  .jb--consent-to-text-radio {
    margin-bottom: 1ex;
    white-space: nowrap;
  }
  .jb--consent-to-text-label {
    margin-left: 2ex;
  }
  .jb--consent-to-text-note {
    margin-top: 8px;
    font-size: 0.9rem !important;
    color: $gray-dark;
  }

  // If they want to change the color of the placeholder text in inputs

  ::-webkit-input-placeholder {
    color: inherit;
  }
  :-ms-input-placeholder {
    color: inherit;
  }
  ::-moz-placeholder {
    color: inherit;
  }
  :-moz-placeholder {
    color: inherit;
  }

  // buttons
  button {
      color: initial !important;
  }

  .jb--button {
    background-color: initial;
    color: initial;
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    white-space: nowrap;
    display: inherit;
    border: 1px solid #ccc;
    &:hover {
      cursor: pointer;
      background: rgba(255, 255, 255, .1)
      // background: #f5f5f5;
      // color: #808080;
    }
    &.disabled {
      // color: #cecece;
      // background-color: #eaeaea;
      cursor: default;
      &:hover {
        cursor: default;
        background: inherit;
      }
    }
  }

  .jb--button-active {
    // background-color: #a9a9a9;
    // color: #fff;
    cursor: default;
    &:hover {
      cursor: default;
      background: inherit;
    }
  }

  // pagination bottom of search page

  .jb--pagination {
    padding: 10px;
    width: 100%;
    display: block;
    border-top: 1px solid #ccc;
    ul {
      float: right;
      display: inline-block;
      list-style: none;
      margin: 0;
      padding: 0;
      width: auto;
      li {
        display: inline-block;
        margin: 0;
        list-style: none;
        // margin: 0 4px;
        a {
          // padding: 10px 10px;
        }
      }
    }
    .jb--button {
      padding: 8px 10px;
      margin: 0 6px;
      border: 0;
    }
    .jb--job-count {
      display: inline-block;
      width: 100%;
      text-align: left;
      margin-top: 10px;
      font-size: 90%;
      width: auto;
      // color: #000;
    }
    i {
    font-style: normal;
    }
    ul li:before {
        content: ' ' !important;
    }
  }
  .jb--submit-resume {
    clear: both;
    margin: 20px 0;
    text-align: center;
    font-size: 110%;
    p {
      display: inline-block;
    }
    a:hover {
      cursor: pointer;
    }
  }

  /* JobSearch Bar */

  .jb--search {
    width: 100%;
  }
  .jb--search-form {
    display: flex;
    margin: 0 0 24px 0;
  }
  .jb--search-keyword,
  .jb--search-location {
    flex: 3;
  }
  .jb--search-button-wrapper {
    flex: 1;
    button {
      width: 100%;
    }
  }

  .searchBarProper {
    flex: 1;
  }

  .jb--search-location {
    background: rgba(0,0,0,.1);
    flex: 1;
    display: flex;
    padding: 5px;
    border-radius: 5px;
  }

  button .jb--search-submitButton {
    margin-left: auto;
    color: white !important;
    background-color: #4bb543;
    width: 100%;
      }

  button .jb--button-submitSearch {
        margin-left: auto;
        color: white !important;
        background-color: #4bb543;
        padding: 8px 20px;
        text-align: center;
        text-decoration: none;
        font-weight: 700;
        font-size: 16px;
        white-space: nowrap;
        display: inherit;
        border: 1px solid #ccc;
        &:hover {
          cursor: pointer;
          background: rgba(255, 255, 255, .1)
          // background: #f5f5f5;
          // color: #808080;
        }
        &.disabled {
          // color: #cecece;
          // background-color: #eaeaea;
          cursor: default;
          &:hover {
            cursor: default;
            background: inherit;
          }
        }
      }

      .jb--search-button-area {
        width: 100%;
        margin: 1rem 0;
      }

      .jb--button-searchEnter {
        width: 100%;
      }

  /* Job Search results */

  .jb--job-listing {
    clear: both;
    display: block;
    cursor: pointer;
    text-align: left;
    // background: #fff;
    padding: 14px 10px;
    border-top: 1px solid #ccc;
    text-decoration: none;
    color: inherit;

    &:first-of-type {

    }
    &:hover {
      background: rgba(0,0,0,.04);
      text-decoration: none;
    }
  }
  .jb--title {
    display: block;
    font-weight: 700;
    clear: both;
  }
  .jb--position-title {
    display: inline-block;
  }
  .jb--location {
    display: inline-block;
    font-weight: 400;
    margin-left: 4px;
    font-size: 90%;
    // color: #808080;
  }
  .jb--location:before {
      content: '- ';
  }
  .jb--date-posted {
    display: inline-block;
    float: right;
    font-size: 90%;
    font-weight: 400;
    // color: #000;
  }
  .jb--description {
    display: block;
    width: 100%;
    margin: 4px 0;
    line-height: 140%;
    // color: #808080;
  }

  /* Job Detail Page */

  .jb--bottom-apply {
    // padding: 14px;
    .jb--button {
      // max-width: 50%;
      // margin: 0 auto;
      display: block;
      // padding-left: 10px;
      right: 16px;
      float: right;
    }
  }

  .jb--job-detail-content {
    width: 100%;
    display: inline-block;
  }

  .jb--job-description {
    line-height: 140%;
    p {
      padding-bottom: 14px;
      -webkit-margin-before: 0;
      -webkit-margin-after: 0;
    }
  }

  // Apply Page
  .jb--error-message {
    text-align: center;
    margin-top: 10px;
    color: #b52a2a;
  }
  .jb--upload-box {
    text-align: left;
    margin-bottom: 24px;
    .jb--label {
      font-size: 120%;
    }
    .jb--file-type {
      font-size: 80%;
      margin-top: 2px;
      color: #808080;
    }
  }

  .jb--apply-loading {
    width: 100%;
    text-align: center;
    margin: 2rem 0;
  }

  .jb--loading {
    color: #D3D3D3;
    width: 100px;
    animation: jbSpin 1.75s linear infinite;

    @keyframes jbSpin {
     0%   { transform: rotate(0deg);   }
     100% { transform: rotate(360deg); }
    }
  }

  // Thanks page
  .jb--thanks {
    text-align: center;
  }

  // Email Alerts
  .jb--alert-signup {
    text-align: center;
    padding: 14px 10px;
    display: block;
    width: 100%;
    clear: both;
    &.jb--alert-signup-open {
      border-top: 1px solid #ccc;
    }
  }

  .jb--alert-signup-open-link {
    display: inline-block;
    white-space: nowrap;
  }

  .jb--alert-signup-form {
    display: block;
    width: 100%;
  }

  .jb--alert-signup-close-link,
  .jb--alert-signup-form input,
  .jb--alert-signup-form .jb--button {
    display: inline-block;
    margin-top: 10px;
  }

  .jb--success-message {
    padding-bottom: 10px;
    text-align: center;
  }

  // Cancel Alert
  .jb--alert-cancel {
    text-align: center;
  }

  .jb--alert-cancel-form {
    text-align: left;
    display: inline-block;
    margin-bottom: 20px;

    .jb--alert-cancel-form-options {
      border: 1px solid #ccc;
      margin-bottom: 20px;
    }

    .jb--alert-cancel-form-options .jb--label {
      padding: 15px !important;
    }

    input.jb--input-radio {
      display: inline-block;
      box-sizing: border-box;
      width: 13px !important;
    	height: 13px !important;
    }

    .jb--button.jb--submit {
      width: 100%;
      margin-right: 0;
    }
  }
}

.jb--upload-file-size-message {
  display: block;
  color: $danger;
  margin: .5rem 0;
}

.jb--search-form-country {
  flex-wrap: wrap;
}

.jb--search-form-country-section {
  display: flex;
  flex: 100%;
  margin-bottom: 10px;
}

.jb--search-form-country select {
  max-width: 250px;
  font-size: 16px;
  border: 1px solid #ccc;
  background: #fff;
  flex: 1;
  margin-right: 14px;
}

.jb--search-form-country jb-autocomplete {
  flex: 1;
}

.jb--search-form-country input[type="location"] {
  width: 100%;
  flex: 1;
}

.jb--search-form-country .jb--search-keyword {
  margin-right: 0;
}

.jb--search-form-country .jb--country-search-indicator {
  flex: 1;
  padding: 5px 10px;
  text-align: center;
  display: inline-block;
  display: flex;
  align-items: center;
  color: #a6a6a6;
  font-style: italic;
  border: 1px solid #ccc;
  background: #ffffff;
  line-height: 1.15rem;
}

.jb--search-location-section {
  display: flex;
  width: 100%;
}

.jb--mobile .jb--search-form-country .jb--country-search-indicator {
  margin-right: 0;
}

.jb--mobile .jb--search-form-country-section {
  flex-wrap: wrap;
}

.jb--mobile .jb--search-form-country-section jb-autocomplete,
.jb--mobile .jb--search-form-country-section select,
.jb--mobile .jb--search-form-country-section button {
  flex: 100%;
  width: 100%;
  max-width: initial;
  margin-right: 0;
}

.jb--mobile .jb--search-form-country-section select {
  margin-bottom: 10px;
}

.jb--mobile .jb--search-form-country-section input[type="location"] {
  margin-right: 0;
  margin-bottom: 10px;
}

.jb--mobile .jb--search-form-country-section button {
  text-align: center !important;
  display: block;
}

// search bar & search button
.jb--search-form-country-section {
  background: rgba(0,0,0,.1);
  padding: 5px;
  border-radius: 5px;
}

.jb--search-form-country jb-autocomplete[type="location"] angucomplete-alt,
.jb--search-form-country jb-autocomplete[type="location"] input,
.jb--mobile jb-country-picker {
    width: 100%;
}

.jb--search-form-country jb-country-picker {
    display: flex;
    margin-left: 10px;
}

// remote status (search bar)

.jb--remote-or-contain {
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.jb--remote-or {
  color: #777;
}

.jb--remote-statusFull-contain {
  display: flex;
  margin-left: 10px;
  background-color: #f4f4f4;
  padding: 5px;
  border-radius: 5px;
}

.jb--remote-statusFull-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  .jb--remote-statusFull-text {
    font-size: 0.9rem;
  }
  .jb--remote-statusFull-desc input {
    margin-top: 15px;
  }
}

//remote status (listing labels)
.jb--remoteStatus-full {
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  margin-left: 4px;
  background: #555555;
  padding: 0 4px;
  border-radius: 2px;
  font-weight: normal;
}

.jb--remoteStatus-partial {
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  margin-left: 4px;
  background: #555555;
  padding: 0 4px;
  border-radius: 2px;
  font-weight: normal;
}

.jb--remoteStatus-hybrid {
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  margin-left: 4px;
  background: #555555;
  padding: 0 4px;
  border-radius: 2px;
  font-weight: normal;
}

.jb--remoteStatus-none {
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  margin-left: 4px;
  background: #555555;
  padding: 0 4px;
  border-radius: 2px;
  font-weight: normal;
}

//remote status (sidebar labels)
.jb--sidebar-remoteStatus-full {
  display: inline-block;
  font-size: 14px;
  margin-top: 8px;
  margin-right: 3px;
  background: #555555;
  padding: 0 8px;
  border-radius: 4px;
  color: #ffffff;
  font-weight: normal;
}

.jb--sidebar-remoteStatus-partial {
  display: inline-block;
  font-size: 14px;
  margin-top: 8px;
  margin-right: 3px;
  background: #555555;
  padding: 0 8px;
  border-radius: 4px;
  color: #ffffff;
  font-weight: normal;
}

.jb--sidebar-remoteStatus-hybrid {
  display: inline-block;
  font-size: 14px;
  margin-top: 8px;
  margin-right: 3px;
  background: #555555;
  padding: 0 8px;
  border-radius: 4px;
  color: #ffffff;
  font-weight: normal;
}

.jb--sidebar-remoteStatus-none {
  display: inline-block;
  font-size: 14px;
  margin-top: 8px;
  margin-right: 3px;
  background: #555555;
  padding: 0 8px;
  border-radius: 4px;
  color: #ffffff;
  font-weight: normal;
}


//featured status (background)
.jb--job-listing-featured {
  background: rgba(0,0,0,.1);
}

//privacy compliance styles
.jb--privacy-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.jb--privacy-close {
  position: absolute;
  top: .5rem;
  right: .5rem;
  padding: 5px 10px;
  float: right;
  font-size: 1rem;
  cursor: pointer;
}

.jb--privacy-main {
  padding: 5px;
}

.jb--privacy-background {
  background: #fff;
  border: none;
}

.jb--privacy-confirm-background {
  background: #fff;
  border: none;
}

.jb--privacy-footer {
  display: flex;
  justify-content: space-around;
  height: 3.5%;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.jb--privacy-describe {
  margin: 5px 0 8px 10px;
}


.jb--privacy-content {
  margin: 5px 0 15px 10px;
  line-height: 20px;
}

.jb--privacy-policy {
  margin: 12px 0 !important;
  text-align: center;
}

.jb--privacy-button-default {
  background-color: $white;
  height: 100%;
  width: 30%;
  color: $black;
  border-color: $gray;
  &:hover,
  &:active {
    color: $black;
    background: #f2f2f2;
    cursor: pointer;
  }
}
.jb--privacy-button-primary {
  height: 100%;
  width: 30%;
  color: $white !important;
  text-shadow: 0 -1px 0 $button-text-shadow;
  background-color: $button-primary-dark;
  background-image: -webkit-linear-gradient($button-primary-light, $button-primary-dark);
  background-image: linear-gradient($button-primary-light, $button-primary-dark);
  border-color: $button-primary-border;
  &:hover {
    color: $white;
    background-color: $button-primary-dark-hover;
    background-image: -webkit-linear-gradient($button-primary-light-hover, $button-primary-dark-hover);
    background-image: linear-gradient($button-primary-light-hover, $button-primary-dark-hover);
    border-color: $button-primary-border-hover;
    cursor: pointer;

  }
}

.jb--privacy-confirmation {
  width: 100%;
  height: 3%;
  margin: 10px 0;
}


// Mobile styles

@media screen and (max-width: 780px) {
  .jb--wrapper {
    .jb--search {
      display: block;
    }
    .jb--jobs {
      padding: 3px;
    }
    .jb--search-form {
      display: block;
      input[type="text"],
      input[type="location"] {
        display: block;
        flex: none;
        width: 100%;
      }
      button {
        width: 100%;
      }
    }
    .jb--title a,
    .jb--location,
    .jb--date-posted {
      display: block;
      float: none;
      margin: 0 0 4px 0;
    }
    .jb--location:before {
        content: '';
    }
    .jb--pagination {
      text-align: center;
      .jb--job-count {
        margin-top: 0;
      }
      ul {
        float: none;
        margin-top: 4px;
      }
    }
    .jb--back,
    h2 {
      margin-bottom: 4px;
    }
    .jb--sidebar {
      display: block;
      width: 100%;
      .jb--button {
        margin-bottom: 4px;
      }
      .jb--sidebar-content {
        padding-bottom: 4px;
      }
    }
    .jb--content {
      display: block;
      width: 100%;
    }
    .jb--bottom-apply .jb--button {
      float: none;
    }
    .jb--applying-for {
      margin-bottom: 0;
      margin-top: 2px;
    }
    #jb-apply.jb--jobs {
      width: 100%;
      display: block;
      .jb--form-row {
        margin-bottom: 0;
      }
      .jb--data-field{
        display: block;
      }
      input[type="text"], input[type="email"], input[type="tel"] {
        display: block;
        margin-right: 0;
        margin-bottom: 12px;
        width: 100%;
      }
    }
    .jb--alert-signup-form input,
    .jb--alert-signup-form .jb--button {
      width: 100%;
      display: block;
    }
  }

  .jb--wrapper .jb--search-location {
    display: block;
    margin-right: 0;
  }

  .jb--search-form-country select {
    max-width: 100%;
    height: 36px;
    margin-top: 6px;
  }

  .jb--search-form-country jb-country-picker {
      display: flex;
      margin-left: 0;
  }
}

@media screen and (max-width: 550px) {

  .jb--remote-statusFull-desc label {
    text-align: center;
  }

  .jb--remote-statusFull-text {
    display: block;
  }
}

@media screen and (max-width: 865px) {
  .jb--country-search-indicator {
    font-size: 12px;
  }
}
