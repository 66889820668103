.card-table-flex {
  padding: 1rem 0 0 1rem;
  overflow: auto;
  display: flex;
  box-sizing: border-box;
  flex-flow: row wrap;
}
.card-flex-two { flex: 2; }
.card-flex-half { flex: 1; }
.card-flex-oneHalf { flex: 1.5; }
.card-flex-emailStat { flex: 1.25; }
.card-flex-full {
  flex: 100%;
  height: auto;
}
.card-wrapper {
  border: solid 1px $gray;
  background: $white;
  overflow: visible;
  box-shadow: 0 5px 5px -4px $rgba-black-15;
  .card-message {
    padding: 1rem;
    color: $gray;
    background: $white;
    text-align: center;
  }
  & .card-content:nth-of-type(odd) {
    background: $card-stripe;
  }
  .card-header-action,
  bb-inline-edit-link {
    margin-left: auto;
  }
}
// stripe overrides for different parts
.activities_history_card {
  .card-wrapper {
    & .card-content:nth-child(odd) {
      background: $white;
    }
  }
}
.add-edit {
  .card-wrapper {
    & .card-content:nth-child(even) {
      background: $card-stripe;
    }
    & .card-content:nth-child(odd) {
      background: $white;
    }
  }
}

.card-header {
  padding: 8px 2px 0 8px;
  user-select: none;
  height: 34px;
  font-size: 1.05rem;
  letter-spacing: .05rem;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  display: flex;

  h3 {
    font-weight: normal;
    margin: 0;
    padding: 0;
    display: inline-block;
  }

  .secondary {
    color: $gray-darkest;
    letter-spacing: 0;
    text-transform: none;
  }

  .icon {
    font-size: 1.2rem;
    margin-right: .5rem;
  }
  bb3-svg[type="log"] .icon,
  bb3-svg[type="schedule"] .icon,
  bb3-svg[type="columns"] .icon {
    font-size: 1.5rem;
  }
  bb3-svg[type="help"] .icon {
    font-size: .9rem;
    margin-top: -4px;
  }
}

.card-content-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Card footer
.card-footer {
  padding: .3rem .5rem;
  overflow: auto;
  background: $white;
  border-top: 1px solid $gray;
  .context-danger {
    margin-top: .25rem;
    display: inline-block;
  }
  .button {
    margin-right: .5rem;
  }
}

// Inline-edit card footer
.inline-save-card-footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
}

// employee on company
.icon-star {
  display: inline-block;
}

.card-left,
.card-right,
.card-full {
  display: inline-block !important;
  vertical-align: top;
  padding: $card-padding;
}
.card-left {
  width: 29%;
  color: $card-left-color;
  text-transform: capitalize;
  span {
    &.primary {
      color: $primary;
    }
  }
  > label {
    display: inline;
  }
}
.card-wrapper .card-left {
  padding-right: .5rem;
  font-size: .9rem;
  line-height: 1.05rem;
  font-weight: 500;
  padding-top: .5rem;
  text-overflow: ellipsis;
  overflow: hidden;
}
.card-right {
  width: 69%;
  max-width: 69%;
  // overflow: hidden;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 98%;
  }
}
.card-full {
  width: 100%;
}
.card-50 {
  width: 49%;
  display: inline-block;
  vertical-align: top;
}
.card-45 {
  width: 44%;
  display: inline-block;
  vertical-align: top;
}
.card-55 {
  width: 54%;
  display: inline-block;
  vertical-align: top;
}
.add {
  font-size: .8rem;
  text-transform: uppercase;
}
// network jobs
.card-half {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  border-right: 1px solid $job-border;
}

.card-padding {
  padding: $card-padding;
  border-bottom: 1px solid $gray;
  align-items: center;
  &.social,
  &.social.network {
    color: $gray-icon;
    a {
      word-break: break-all;
    }
  }
}
.card-padding.activity {
  overflow: hidden;
}
// Special styling for Network Activities
.network.person bb3-network-profile-activity bb3-activity-row-network .card-padding,
.activities_history_card .network .card-padding {
  border-left: 3px solid $network;
  background: $network-notification-bg;
}

// for Action column on Person Nominations tab
.person .action-container {
  width: 94px;
  max-width: 94px;
  a.disabled {
    color: $gray;
    cursor: not-allowed;
  }
  .social bb3-svg[type="mail"] svg {
    font-size: 1.2rem;
  }
}

.action-container > button {
  border: none;
  background: transparent;
}
.person .action {
  width: 94px;
  max-width: 94px;
  height: 26px;
  font-size: .9rem;
  line-height: 1.05rem;
}
.nominations-empty {
  max-width: 560px;
  font-size: 1.05rem;
  line-height: 1.3rem;
  border: 1px solid $person-border;
  margin: 1rem auto;
  padding: .5rem 0;
  background: $white;
  div {
    text-align: center;
    border-bottom: 1px dotted $person-border;
  }
  img {
    max-width: 75px !important;
    max-height: 75px !important;
    padding-bottom: .5rem 0;
  }
  p {
    padding: .25rem 3rem;
  }
  ul {
    font-size: 1.1rem;
    line-height: 1.3rem;
    margin: 10px 40px 10px 70px;
    list-style: disc;
    li {
      margin-bottom: .5rem;
    }
  }
}
.job .nominations-empty {
  border: 1px solid $job-border;
}
.network .nominations-empty {
  border: 1px solid $network-border;
}
.job a .social {
  color: $job-link;
}
.person a .social {
  color: $person-link;
}
.job .action-cell {
  a.job:hover .social {
    color: $job;
  }
}
.person .action-cell a:hover .social {
  color: $person;
}
.action-cell {
  .social {
    margin-right: .1rem;
  }
  bb3-svg[type="trash"].social .social,
  bb3-svg[type="decline"].social .social {
    &:hover {
      color: $danger;
    }
  }
  bb3-svg {
    cursor: pointer;
  }
  .disabled,
  .disabled a,
  .disabled bb3-svg {
    color: $gray-darker !important;
    &:hover {
      color: $gray-darker !important;
      text-decoration: none;
      cursor: not-allowed;
      .social {
        color: $gray-darker;
        text-decoration: none;
        cursor: not-allowed;
      }
    }
  }
}
.job a.disabled .social {
  color: $gray-darker !important;
}

// differences in Network Candidates decision between in table or in activity
bb-network-request-decision .action-container {
  margin-top: .1rem;
  font-size: 1rem;
}
bb-network-request-decision .action {
  width: 100% !important;
  max-width: 99% !important;
  height: auto !important;
  margin-top: .1rem;
}
.action-container.share-decline-action {
  width: 100%;
  max-width: 100%;
}
.action-container.share-decline-action > button {
  display: inline-block;
  margin-bottom: .1rem;
  margin-right: 1rem;
  font-size: .9rem;
  span {
    color: $gray-darkest;
  }
  .social {
    margin-right: 0;
    font-size: 1.15rem;
  }
}
.action-container.share-decline-action > button:hover {
  span {
    color: $black;
  }
  bb3-svg[type="share"] .social {
    color: $person;
  }
  bb3-svg[type="decline"] .social {
    color: $danger;
  }
}

// dealing with ICONS in general
.social {
  color: $gray-icon;
}
.social svg {
  font-size: 1.4rem;
}
.social-on,
.social-on svg.social {
  color: $black-faded;
}
bb3-svg[type="linkedin"].social-on .icon {
  color: $linkedin;
}
bb3-svg[type="facebook"].social-on .icon {
  color: $facebook;
}
bb3-svg[type="monster"].social-on .icon {
  color: $monster;
}
bb3-svg[type="twitter"].social-on .icon {
  color: $twitter;
}
bb3-svg[type="network-share"].social-on .icon {
  color: $network;
}
bb3-svg[type="network-feed"].social-on .icon {
  color: $network;
}

.advertising-card .jb-advertising-featured {
  margin-left: 38px;
  font-size: .9rem;
}

// card specific content
.card-content {
  .social,
  .social-on {
    margin-right: .2rem;
  }
}
// add border to top
.card-content.bt {
  border-top: 1px solid $gray;
}

// Jobs Dashboard
.advertising-cell {
  padding-top: .5rem;
  height: 70px;
  .icon {
    font-size: 1.35rem;
    margin-right: .25rem;
  }
  .action {
    font-size: 1rem;
    line-height: 1.2rem;
    color: $black;
  }
  .second-line {
    font-size: .85rem;
    line-height: 1.05rem;
  }
  bb3-svg.post:hover {
    cursor: pointer;
    .icon {
      color: $job-link;
    }
  }
  bb3-svg.job-post:hover {
    cursor: pointer;
    .icon {
      color: $job-link;
    }
  }
  bb3-svg.network:hover {
    cursor: pointer;
    .icon {
      color: $job-link;
    }
  }
  bb3-svg.connect:hover {
    cursor: pointer;
    .icon {
      color: $warning;
    }
  }
  bb3-svg.danger:hover {
    cursor: pointer;
    .icon {
      color: $danger;
    }
  }
  section {
    margin-right: .3rem;
    margin-top: 1px;
    position: relative;
    &.job-board {
      top: 2px;
    }
    &.job-post {
      top: -1px;
    }
    &.network-share {
      top: 1px;
    }
    &.remove-advertising {
      top: 2px;
    }
  }
}
// override for Hover instructions on first row of Jobs Dashboard
tr .second-line-hover {
  display: none;
}
tr:first-of-type .second-line-hover {
  display: block;
}

// shared with all job share
.social.connect:hover {
  color: $warning !important;
}

.incard-head {
  font-size: .85rem;
  font-weight: 500;
  background: $gray-lighter;
  padding: .2rem .6rem;
}
a.remove-advertising {
  color: $gray !important;
  margin-top: .1rem;
  &:hover {
    color: $danger !important;
  }
}
a.repost {
  color: $gray !important;
  margin-top: .1rem;
  &:hover {
    color: $job-link !important;
  }
}
.icon-small {
  color: $gray-darkest;
  margin-bottom: 2px;
  font-size: .9rem;
  &:hover {
    color: $job;
  }
  a {
    color: $gray-darkest;
  }
}
.job-post {
  .card-left {
    width: 29%;
    min-width: 100px;
    font-size: 1rem;
  }
  .card-right {
    width: 69%;
  }
  label {
    text-transform: none;
  }
}
.advertising-job-info {
  padding: .5rem;
}
.account-options {
  border: 1px solid $gray;
  padding: .35rem;
  margin: .25rem 0;
}
.card-list {
  font-size: .8rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: $gray-darkest;
  margin-top: .25rem;
  letter-spacing: .02rem;
  li {
    display: inline;
    &:before {
      color: $gray-darker;
      content: "\2022";
      font-weight: 300;
      margin: 0 .2rem;
    }
    &:first-child:before {
      content: "";
      margin: 0;
    }
  }
}

// Network Advertising section
.network-advertising {
  .incard-head {
    background: $network-lighter !important;
    border-top: 1px solid $network !important;
  }
  a,
  a .social {
    color: $network-link !important;
  }
  .card-content:nth-of-type(odd) {
    background: #fcf8f1 !important;
  }
  .card-content:nth-of-type(even) {
    background: $white !important;
  }
  .zero-network-share:after {
    content: 'Share with TE Network';
  }
  .zero-network-feed:after {
    content: "Share with TEN Feed (other recruiter's sites)";
  }
}

// Janky way to do this, but easier than messing with the code
.job-board-advertising {
  .zero-job-board:after {
    content: "Post to My Agency's Job Board";
  }
  .zero-linkedin:after {
    content: 'Must be posted to Job Board';
  }
  .zero-facebook:after {
    content: 'Must be posted to Job Board';
  }
  .zero-twitter:after {
    content: 'Must be posted to Job Board';
  }
}
.job-posting-advertising {
  .incard-head {
    border-top: 1px solid $gray;
  }
  .testing {
    &:after {
      content: 'Post to External Sites';
    }
  }
}

// Modal and Add Edit
.creating-list {
  margin: .2rem .5rem;
}

.card-flex {
  position: relative;
  .card-wrapper {
    position: relative;
    margin: 0 $all-margins $all-margins 0;
    // card-padding to update
    .edit-card {
      display: none;
      z-index: 30;
    }
    .save-card {
      display: none;
      z-index: 10;
    }
  }
}

.record-information {
  width: 100%;
}

.card-table-flex > .card-flex,
.card-dash-flex > .card-flex  {
  flex: 0 0 33.3333%;
}

.card-table-flex-half {
  display: flex;
  .card-flex {
    flex: 0 0 50%;
  }
}

// Mobile styles...should they be moved?
@media (max-width: 1200px){
  .card-table-flex > .card-flex {
    flex: 1 0 50%;
  }
}

@media (max-width: 1000px){
  .card-table-flex > .card-flex {
    flex: 1 0 98%;
  }
  .card-wrapper {
    height: auto;
  }
}

// // Validation Styles
.card-error {
  padding: .35rem .5rem;
  background: $danger;
  color: $white;
}
.card-delete-section {
  display: inline-block;
  margin-top: .25rem;
}
.card-delete-section .icon {
  margin-left: .25rem;
}

// Resume Card
.resume-name {
  padding: .5rem 1rem 0 .5rem;
}

ul.resume-list {
  font-size: .85rem;
  color: $gray-darkest;
  li {
    display: inline-block;
  }
}

// Inline Edit
.card-content.card-separator {
  margin-bottom: 2px !important;
  padding-bottom: 1px !important;
}
.person .card-separator {
  border-bottom: 1px solid $person;
}
.company .card-separator {
  border-bottom: 1px solid $company;
}
.job .card-separator {
  border-bottom: 1px solid $job;
}
.placement-form .card-separator {
  border-bottom: 1px solid $placement;
}
.placements .placement-form .card-separator {
  border-bottom: 1px solid $placement;
}

.datasheet-mobile {
  display: flex;
  flex-wrap: wrap;
  margin-top: .3rem;
}

bb3-network-jobs-info-cards .datasheet-mobile {
  margin-top: 0;
}

.datasheet-desktop {
  display: flex;
  margin: $all-margins 0 0 $all-margins;
  // card-padding to update
}

.simple-card {
  background: $white;
  border-radius: 4px;
  border: 1px solid $gray;
  border-bottom-width: 2px;
  font-size: 1rem;

  .simple-card--section {
    padding: 1rem;
  }

  .simple-card--header {
    font-size: 1.75rem;
    letter-spacing: initial;
    text-transform: none;
    margin: 1rem 0;
    text-align: center;
  }

  .simple-card--subheader {
    font-size: 1.25rem;
    letter-spacing: initial;
    text-transform: none;
    margin: 1rem 0;
    text-align: center;
  }

  .simple-card--xdivider {
    width: 100%;
    height: 1px;
    background: $gray;
  }

  .simple-card--ydivider {
    background: $gray;
    width: 1px;
  }

  .simple-card--footer {
    display: flex;

    button {
      margin: 0 .5rem;
      flex: 1;
    }
  }

  .simple-card--stat {
    text-align: center;

    .simple-card--stat-number {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  .simple-card--form-section {
    display: flex;
    flex-wrap: wrap;

    fieldset {
      flex: 1;

      label {
        font-weight: bold;
      }

      input {
        width: 100%;
      }
    }
  }

  .simple-card--close {
    position: absolute;
    top: .5rem;
    right: .5rem;

    bb3-svg svg {
      font-size: 1.5em;
      color: $gray-darker;
      margin-right: 0;
    }

    &:hover {
      cursor: pointer !important;
    }

    &:hover bb3-svg svg {
      color: $danger;
    }
  }

  .simple-card--confirmation {
    display: flex;
    margin-bottom: 1rem;

    .bb3-checkbox {
      margin-right: .5rem;
    }
    p {
      margin-top: .3rem;
    }
  }

  .simple-card--loading-indicator {
    text-align: center;
    display: block;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .simple-card--purchase-items {
    padding-bottom: .5rem;
    margin-bottom: 1rem;
    margin-top: 2rem;

    li {
      display: flex;
      margin-bottom: .5rem;
      font-weight: bold;
    }

    .simple-card--purchase-items-total {
      border-top: 1px solid $gray;
      margin-top: .5rem;
      padding-top: .5rem;
      font-size: 1.2rem;
    }

    .simple-card--purchase-items-price {
      margin-left: auto;
    }

    .simple-card--purchase-items-name {
      display: flex;
      flex-wrap: wrap;
    }

    .simple-card--purchase-items-discount-amount {
      margin-left: auto;
      font-weight: normal;
      font-style: italic;
      color: $success;
    }

    .simple-card--purchase-items-description {
      font-weight: normal;
      font-style: italic;
    }

    .discount {
      color: $success;
    }
  }

  .number-input {
    width: 40px;
    text-align: center;
  }
}

.simple-card--columns {
  display: flex;

  .simple-card--section {
    flex: 1;
  }
}

.simple-card--choose-plan {
  min-height: 225px;
  display: flex;

  .choose-plan--checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 .25rem;
  }

  .choose-plan--plan {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;
    flex: 1;
    text-align: center;
    padding: 1.5rem 0;
    position: relative;

    .discount-indicator {
      color: $success;
      position: absolute;
      top: .5rem;
      margin: 0 auto;
      left: 0;
      right: 0;
    }

    h2 {
      font-size: 1.75rem;
      margin-top: 1rem;
      text-transform: none;
      font-weight: normal;
      letter-spacing: initial;
    }

    h3 {
      font-size: 2rem;
      margin: 1rem 0;
    }

    h4 {
      font-size: 1.25rem;
      margin-top: 0;
    }

    > * {
      width: 100%;
    }
  }

  .choose-plan--subplan {
    padding: 1rem;
    text-align: left;
    display: flex;

    h2 {
      font-size: 1.25rem;
      font-weight: bold;
      margin-top: 0;
    }

    select {
      height: auto;
      margin: 0;
    }

    &:first-child {
      border-bottom: 1px solid $gray;
    }
  }
}

.supported-credit-cards {
  max-width: 250px;
  flex: 100%;
  display: flex;
  margin-bottom: .25rem;
  justify-content: flex-start;
  .credit-card {
    width: auto;
    height: 24px;
    border: 1px solid $gray;
    background: $white;
    border-radius: 3px;
    display: flex;
    margin-right: .25rem;
    overflow: hidden;
    align-items: center;
    justify-content: center;
  }
  img {
    height: 100%;
    width: auto;
  }
}

.card-load-more {
  padding: .5rem 0;
  text-align: center;
  font-size: 1.1rem;
  background: $gray-lightest;

  bb-loading-spinner {
    display: inline-flex;
    width: auto;
    height: auto;
    padding: 0;
  }
  &.bt {
    border-top: 1px solid $gray;
  }
}

.datasheet-footer-card {
  margin-bottom: 60px !important;
}

.comment-card pre,
.custom-field-card pre,
.datasheet-footer-card pre,
.sent-email .card-wrapper pre, {
  white-space: pre-wrap;
}

.sent-email .card-wrapper pre {
  word-break: break-all;
}

.resume-card,
.job-description-card {
  margin: $all-margins;
  margin-top: 0;
}

.social-card bb3-url-display {
  word-break: break-all;
  display: inline-block;
}

.card-alert {
  border: 1px solid $network-link;
  padding: .5rem 1rem;
  color: $network-link;
  background: $te-yellow-light;
  margin: .5rem 0;
}

.action-card {
  justify-content: space-between;
  > section {
    margin: .25rem;
  }
  bb3-svg .icon {
    font-size: 1.6rem;
  }
  div {
    font-size: .9rem;
    span:nth-of-type(1) {
      font-size: 1.2rem;
      line-height: 1.1rem;
      display: block;
      font-weight: 600;
    }
    span:nth-of-type(2) {
      line-height: 1rem;
      display: block;
    }
  }
  .disabled {
    color: $gray-darker;
    &:hover {
      color: $gray-darker;
      text-decoration: none;
      cursor: not-allowed;
    }
  }
  // remove when icons get hooked up - for beta users
  // a:hover {
  //   cursor: not-allowed;
  // }
}

.messageInfo {
  padding-top: 8px;
}

.messageLink {
  padding-top: 3px;
  padding-right: 5px;
}
