// Font
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Border Variables
$border-radius: 0;
$card-border-radius: 3px;

// Size Variables
$min-width: 1000px;

$all-margins: .7rem;

.all-margins {
  margin: $all-margins;
}

// Grayscale Colors
$black: #111;
$almost-black: #333;
$white: #FFF;
$gray: #CCC;
$gray-darker: darken($gray, 15%);
$gray-dark: darken($gray, 22%);
$gray-darkest: #707070;
$gray-icon: #aaa;
$gray-lighter: lighten($gray, 12%);
$gray-lightest: lighten($gray, 17%);
$heading-bg: #e2e2e2;

// Text Colors
$text-shadow: rgba(0, 0, 0, 0.004);
$link: #434994;
$link-dark: #141B74;
$black-faded: #505050;

// Element Colors
$header: #f8f2e5;
$header-hover: rgba(214, 195, 161, 0.29);
$highlight: #FFE873;
$color-border: $gray;
$card-stripe: $gray-lightest;
$tab-background: $white;

$te-blue: #262d6c;
$te-blue-light: #666c93;
$te-yellow: #d49638;
$te-yellow-light: #f6e4c4;

$gray-nav: #444;

// Base Colors
$dashboard: #596098;
$job: #32953e;
$person: #2672b8;
$company: #e17b3a;
$hotlist: #cf4041;
$task: #8363ab;
$report: #8363ab;
$email: #8363ab;
$settings: #4b5388;
$blue: $link;
$network: #F1A81E;
$placement: #3a8a89;

// Link colors
$job-link: #388a42;
$person-link: #1e6293;
$company-link: #b6642f;
$hotlist-link: #a83839;
$task-link: #76438a;
$report-link: #76438a;
$email-link: #76438a;
$settings-link: #424977;
$network-link: #946408;

// Card Header Buttons
$job-button: #64a96c;
$person-button: #5e99cf;
$company-button: #e89b68;
$hotlist-button: #e47a7a;
$task-button: #a27faf;
$report-button: #a27faf;
$email-button: #a27faf;
$settings-button: #4b5388;

// Card Headers
$job-lighter: #f0faf1;
$person-lighter: #f0f8fe;
$company-lighter: #fcf5f0;
$hotlist-lighter: #fbf0f0;
$task-lighter: #eae1ec;
$report-lighter: #f6f2f7;
$email-lighter: #f6f2f7;
$settings-lighter: #ebeefa;
$network-lighter: #fff8e9;

$network-notification-bg: #fffcf8;
$settings-notification-bg: #f7f6fb;

//Cell Expanded
$job-expanded: $job-lighter;
$company-expanded: $company-lighter;
$person-expanded: $person-lighter;

// Card Borders
$job-border: #b9ceba;
$person-border: #b4c9db;
$company-border: #e9cdba;
$hotlist-border: #ddcece;
$task-border: #d0cad3;
$report-border: #d0cad3;
$email-border: #d0cad3;
$settings-border: #b8b9bf;
$network-border: #d7cebd;

// task = planner
$planner: $task;
$planner-link: $task-link;
$planner-button: $task-button;
$planner-lighter: $task-lighter;
$planner-border: $task-border;

// TEN Colors
$ten-orange: #e2991c;
$ten-dark-blue: #272a6e;
$ten-light-blue: #cdd0e5;

// Help
$help-bg: #d7982e;
$help-shadow: #c48925;
$help-content-bg: #fef9f2;
$help-button: #f6ddb3;

// Settings
$settings-nav-active: #d4d7e9;

// Status Colors
$selected: #FFF5CC;
$primary: #007DFF;
$warning: #9b6d2e;
$success: #40a86b;
$danger: #cf4041;

// Social Colors
$facebook: #3b5998;
$linkedin: #007bb6;
$twitter: #00aced;
$gplus: #dd4b39;

//Posting Venue Colors
$monster: #a267a2;

// rgba Colors
$rgba-white-none: rgba(255,255,255,0);
$rgba-white-half: rgba(255,255,255,.5);
$rgba-white-full: rgba(255,255,255,1);
$rgba-black-10: rgba(0,0,0,0.1);
$rgba-black-15: rgba(0,0,0,0.15);
$rgba-black-25: rgba(0,0,0,0.25);
$rgba-black-50: rgba(0,0,0,0.5);
$rgba-black-75: rgba(0,0,0,0.75);
$rgba-black-full: rgba(0,0,0,1);
$rgba-black-0: rgba(0,0,0,0);
$rgba-gray-10: rgba(119, 119, 119, .1);
$rgba-gray-25: rgba(119, 119, 119, .25);
$rgba-gray-5: rgba(119, 119, 119, .5);
$rgba-gray-75: rgba(119, 119, 119, .75);
$pipeline-shadow: rgba(46, 154, 60, .25);
$hotlist-shadow: rgba(133, 100, 88, .25);
$rgba-red-25: rgba(192, 57, 43, 0.25);
$rgba-job-full: rgba(46, 154, 60, 1);
$rgba-job-half: rgba(46, 154, 60, .5);

//buttons
$button-text-shadow: rgba(0,0,0,0.35);
$button-primary-light: #8add6d;
$button-primary-dark: #60b044;
$button-primary-border: #5ca941;
$button-primary-light-hover: #79d858;
$button-primary-dark-hover: #569e3d;
$button-primary-border-hover: #4a993e;
$button-danger-light: #f95054;
$button-danger-dark: #cb282c;
$button-danger-border: #c3262a;
$button-danger-light-hover: #f8373c;
$button-danger-dark-hover: #b62427;
$button-danger-border-hover: #b03527;

//scrollbars in tables
$scrollbar: #dbdbdb;

// TEN Colors

$ten: #272a6e;
$ten-darker: darken($ten, 15%);
$ten-lighter: lighten($ten, 15%);
$ten-icon-link: #eca000;

// padding in card element
$card-padding: 5px 5px 5px 8px;

// new placement form card-left bg
$card-left-bg: #f1f1f1;
$card-left-color: #444;
