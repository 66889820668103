// General modal styles
.modal-bg {
  position: fixed;
  background: radial-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, .85));
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.modal-bg.draggable {
  background: transparent;
  pointer-events: none;
  .modal-activity-container {
    pointer-events: auto;
    // width: 800px;
    .input-style.textarea {
      margin: 0;
    }
  }
  .modal-activity-container .card-wrapper {
    border: 1px solid $gray-dark;
    box-shadow: 0 0 15px $rgba-black-25;
  }
  .modal-activity-container .card-wrapper .card-right {
    overflow: visible;
  }
  .modal-activity-details {
    width: 100% !important;
    padding-top: 0 !important;
  }
  .modal-activity-details textarea {
    min-height: 140px;
    min-width: 100%;
  }
}

.modal-bg.draggable .modal-activity-container.network-recruiters {
  .jump-list {
    right: -14px !important;
    width: 800px;
  }
}
// can be removed when side step modal is merged
.modal-bg.draggable .modal-activity-container.network-recruiters.modal-temporary {
  .jump-list {
    right: -320px !important;
    width: 800px;
  }
}

.modal-bg-blur {
  filter: blur(10px);
}

.modal-container {
  overflow: visible;
  z-index: 7000;
  margin: 0;
  max-height: 90%;
  position: relative;
  border: 1px solid $black;
  .card-content:nth-of-type(odd) {
    background: $white;
  }
  &.modal-activity-container {
    border: 0;
  }
  .card-wrapper {
    margin: 0;
    border: 0;
    overflow: initial;
    .card-full {
      padding: 3px 3px 0 3px !important;
    }
  }
  .card-header {
    background: #5b5d6d !important;
    border-bottom: 1px solid $gray !important;
    padding: 0 .5rem;
    height: 36px !important;
    text-transform: none;
    font-size: 1.4rem;
    line-height: 1.5rem;
    color: $white;
    display: flex;
    align-items: center;
    > bb3-svg {
      font-size: 1.3rem;
      margin-right: .25rem;
      color: $white;
    }
    bb3-svg[type="x"] {
      color: $gray-lightest !important;
      margin-right: 0;
      position: relative;
      top: .15rem;
      &:hover {
        color: $white !important;
        cursor: pointer;
      }
      .icon {
        font-size: 1.6rem;
        margin-right: 0;
      }
    }
  }
  .card-footer {
    text-align: center;
    padding: .5rem .25rem;
    border-top: 1px solid $gray;
    background: $gray-lighter;
    background: -moz-linear-gradient(top,  #dedede 0%, #ececec 50%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #dedede 0%,#ececec 50%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #dedede 0%,#ececec 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#dedede', endColorstr='#ececec',GradientType=0); /* IE6-9 */
  }
  .records,
  .related-records {
    overflow-x: auto !important;
    max-height: 124px;
  }
  h3.read-only,
  .not-shared {
    color: $gray-darkest;
    font-style: italic;
    font-weight: 500;
  }
  .shared {
    font-weight: 600;
  }
  .read-only {
    height: 240px;
    overflow-y: auto;
    border: 1px solid $gray;
    background: $white;
    padding: .25rem;
  }
  // mass ownership confirm dialog
  &.mass-confirm {
    .card-wrapper {
      background: $gray-lightest;
      .bottom-section {
        padding-left: 98px;
        padding-bottom: 14px;
        line-height: 1.25rem;
      }
    }
  }
  .loading-modal-height {
    height: 310px;
  }
  .card-content.card-input-padding {
    margin: .2rem;
  }
}

.close-modal {
  padding: 0;
  margin: 0 0 0 auto;
}
.close-modal:hover bb3-svg svg {
  color: $danger;
}

.modal-w1 { width: 20rem; }
.modal-w2 { width: 30rem; }
.modal-w3 { width: 40rem; }
.modal-w4 { width: 50rem; }
.modal-w5 { width: 60rem; }

.modal-content-overflow {
  height: 20rem;
  max-height: 50vh;
  overflow-y: auto;
  &.tall {
    height: calc(75vh - 86px);
    max-height: calc(75vh - 86px);
  }
}

.modal-content-overflow-lg {
  height: 40rem;
  max-height: 75vh;
}

.modal-container.modal-w1 .card-wrapper {
  .card-left {
    width: 56px !important;
  }
  .card-right {
    width: 76px !important;
    select {
      width: 190px !important;
      max-width: initial;
    }
  }
}

// Modal select list
.modal-select-list {
  .record-chiclet bb3-record-icon,
  .record-chiclet bb3-record-icon bb3-svg,
  .record-chiclet bb3-record-icon bb3-svg .icon {
    margin-right: 0 !important;
  }
  bb3-record-icon {
    margin-right: .25rem;
  }
  > li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid $gray;
    padding: .25rem .5rem;
  }
  > li:hover {
    cursor: pointer;
  }
  > li.selected bb3-svg[type="check"] {
    color: $network;
  }
  > li.highlight-lighter:hover {
    background: #FDF0C7 !important;
  }
  > li:not(.selected):hover {
    background: $gray-lightest;
  }
  .list-inline li:after {
    margin: 0 0 0 .4rem !important;
  }
  .inline-icon {
    right: .1rem;
    top: -2px;
  }
  .file-list {
    margin-top: .25rem;
    border: 1px solid #ccc;
    width: 100%;
    section {
      padding: .25rem .5rem;
    }
  }
  .job-list-left {
    width: 75%;
    display: inline-block;
  }
  .job-list-right {
    width: 23%;
    display: inline-block;
    text-align: right;
  }
  // needed because html lint adds line break that separates s
  .lint-s {
    margin-left: -3px;
  }
  .tr .record-chiclet {
    text-align: left !important;
  }
}
// needed for lists with remove x
.modal-select-list.with-remove li:last-of-type:after {
  content: '';
  margin: 0 !important;
}
.modal-select-list.with-remove .list-inline {
  width: 95%;
}
.modal-select-list.with-remove .remove-x {
  padding-top: 4px;
  color: $gray-darker;
}
.modal-select-list.with-remove li {
  padding: .25rem 0 .25rem .25rem;
}

// Confirmation modal
.modal-confirm {
  border-radius: 4px;
  overflow-y: auto;
  .card-content {
    width: 100%;
    font-size: 1.5rem;
    line-height: 1.85rem;
    padding: 1.5rem;
    display: flex;

    .modal-confirm-icon {
      height: 2rem;
      width: 2rem;
      flex-shrink: 0;
    }
  }
}

// Modal tabs
.modal-container .tabs-nav-sm {
  white-space: nowrap;
  overflow-x: auto;
}

// In-modal chiclets
.modal-container .record-chiclet {
  margin: .25rem .25rem 0 0;
  font-size: .9rem !important;
}

.modal-container .fr .record-chiclet {
  margin-top: .1rem !important;
}

.modal-container .record-chiclet.more-chiclet {
  margin-left: -.8rem;
}

.modal-list-form {
  display: flex;
  flex-wrap: wrap;
  padding: 2px 5px 2px 8px !important;
  background: $gray-lighter;
  background: -moz-linear-gradient(top,  #ececec 50%, #dedede 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #ececec 50%,#dedede 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #ececec 50%,#dedede 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ececec', endColorstr='#dedede',GradientType=0); /* IE6-9 */
  input[type="text"] {
    flex: 1;
    min-width: 250px;
    width: 100%;
  }
}
.contact-card .modal-list-form {
  padding: 0;
  background: none;
}

.modal-select-list-item {
  .modal-select-list-item--icon {
    margin-right: 1rem;
  }
  .modal-select-list-item--content {
    display: flex;
    flex: 1;
    align-items: center;
  }
  .modal-select-list-item--secondary {
    text-align: right;
    margin-left: auto;
    select {
      height: 24px;
      margin-top: 0;
      font-size: 1rem;
      width: 100px;
    }
  }
  .modal-select-list-item select {
    margin: 0;
    height: 22px;
  }
  &.disabled {
    * { color: $gray-dark; }

    &:hover {
      cursor: not-allowed !important;
    }
  }
}

// Social Media Share (LinkedIn)
.modal-share {
  .card-full {
    width: 100%;
    padding-bottom: 0;
  }
  .image-holder {
    vertical-align: middle;
    display: inline-block;
  }
  img {
    max-width: 48px !important;
    width: 48px;
    height: 48px;
    vertical-align: middle;
  }
  label {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1.5rem;
    margin-bottom: .25rem;
  }
  textarea {
    min-height: 50px;
    width: 99%;
    border: 1px solid #e2e2e2;
  }
  input.job-title {
    font-weight: bold;
    width: 100%;
    margin-bottom: .25rem;
    border: 1px solid #e5e5e5;
  }
  textarea.job-description {
    min-height: 90px;
    width: 100%;
    border: 1px solid #e5e5e5;
  }
  .li_link {
    width: 99%;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: $gray-lightest;
    padding: .5rem;
    margin-bottom: .4rem;
  }
}

.modal-footer-link-left {
  position: absolute;
  left: 1rem;
}

.modal-selected-list {
  margin: 0 .25rem .5rem .25rem;
  border: 1px solid $gray;
  border-radius: .25rem;
  background: $white;
  text-align: left;
  max-height: 80px;
  overflow-y: auto;
  padding: .25rem;
}
.card-network-footer {
  border-top: 2px solid $network;
  border-bottom: 2px solid $network;
  background: $network-lighter;
  padding: .3rem .5rem;
  // take a look at updating this when Pipeline piece is in place
  .modal-network-recruiters.dib.mr2 {
    width: 270px;
    height: 42px;
    overflow-y: auto;
  }
  .card-left-network-activity {
    font-size: .9rem;
    font-weight: 600;
    color: $card-left-color;
    // width: 240px;
  }
  .record-chiclet {
    margin: 0 .05rem .15rem 0;
    &.text-overflow {
      max-width: 190px;
    }
  }
}
.modal-network-details {
  text-align: left;
  overflow-y: auto;
  padding: .3rem 1rem;
  height: 30px;
  text-transform: none;
  input[type="checkbox"] {
    border: 1px solid darken($network-border,5%);
  }
}

// Network Share/Request modal
.modal-comment {
  border-top: 1px solid $network-border;
  .card-header {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }
  .comments {
    width: 99%;
    height: 50px;
    padding: .5rem;
    overflow: visible;
    border: 1px solid $gray-lighter;
    color: $gray-darker;
  }
}

.sms-modal-container .sms-compose-body {
  resize: vertical;
  min-height: 120px;
  max-height: 360px;
}

.sms-modal-container textarea.input-style {
  width: 100%;
  margin: 0;
}
